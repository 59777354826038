import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = {}
          if (options.searchValue) {
            payload.search = options.searchValue
          }
          if (options.filter && options.filter[0]) {
            // eslint-disable-next-line no-unused-vars
            const [field, operation, value] = options.filter[0]
            payload.search = value
          }

          payload.take = options.take
          payload.skip = options.skip ?? 0

          return store.dispatch('user/fetchLedgers', payload).then(result => {
            if (!result.data) {
              return {
                data: [],
                totalCount: 0,
              }
            }

            return {
              data: result.data,
              totalCount: parseInt(result.total_items, 10),
            }
          }).catch(() => {
            throw new Error('Something went wrong. Please try again later.')
          })
        },
      }),
      paginate: true,
      pageSize: 25,
    })
  }
}
