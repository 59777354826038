<script>

export default {
  name: 'PaymentMethodIcon',
  props:
  {
    paymentMethod: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    iconFolder() {
      return this.dark ? 'dark' : 'light'
    },
    iconName() {
      switch (this.paymentMethod) {
        case 'westerunion':
        case 'wester_union':
          return 'wester_union'
        case 'world_pay':
        case 'worldpay':
          return 'world_pay'
        case 'visa_electron':
        case 'visaelectron':
          return 'visa_electron'
        case 'visa':
          return 'visa'
        case 'mastercard':
        case 'master_card':
          return 'mastercard'
        case 'discover':
          return 'discover'
        case 'paypal':
        case 'pay_pal':
          return 'paypal'
        case 'amazon':
          return 'amazon'
        case 'cirrus':
          return 'cirrus'
        case 'maestro':
          return 'maestro'
        case 'american_express':
        case 'americanexpress':
          return 'american_express'
        default:
          return false
      }
    },
  },
}
</script>

<template>
  <div class="payment-method-icon">
    <img
      v-if="iconName"
      :width="width"
      :src="require(`../assets/images/paymentMethodIcons/${iconFolder}/${iconName}.png`)"
      :alt="paymentMethod"
    >
  </div>
</template>

<style scoped lang="scss">

</style>
